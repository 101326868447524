import { graphql, useStaticQuery } from 'gatsby';
import {
  ContentfulPulsoTecnico,
  ContentfulCarteraModelo,
  ContentfulDiarioEconomico,
  ContentfulMercadosALaApertura,
  ContentfulFlashEmisora,
  ContentfulFlashTecnico,
  ContentfulFlashEconomico,
  ContentfulCarteraTecnico,
  ContentfulFlashInternacional,
  ContentfulDiarioInternacional,
  ContentfulFlashRentaFija,
  ContentfulEstrategiaDeInversion,
  ContentfulGenerico,
  ContentfulReporteTrimestral,
  ContentfulComentarioSemanal,
  ContentfulComentarioDiario
} from '../../../types/graphql-types';
import parseDate from '../../utils/parseDate';
import { HomePosts as HomePostsProps } from '../../models/HomePosts';

export const useHomePosts = (): HomePostsProps => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }

      pulsoTecnico: allContentfulPulsoTecnico(
        sort: { fields: createdAt, order: DESC }
        limit: 4
      ) {
        totalCount
        edges {
          node {
            ...pulsoTecnicoPreview
          }
        }
      }

      generico: allContentfulGenerico(
        sort: { fields: createdAt, order: DESC }
        limit: 4
      ) {
        totalCount
        edges {
          node {
            ...genericoPreview
          }
        }
      }

      carteraModelo: allContentfulCarteraModelo(
        sort: { fields: createdAt, order: DESC }
        limit: 1
      ) {
        totalCount
        edges {
          node {
            ...carteraModeloPreview
          }
        }
      }

      carteraTecnico: allContentfulCarteraTecnico(
        sort: { fields: createdAt, order: DESC }
        limit: 1
      ) {
        totalCount
        edges {
          node {
            ...carteraTecnicoPreview
          }
        }
      }

      diarioEconomico: allContentfulDiarioEconomico(
        sort: { fields: createdAt, order: DESC }
        limit: 5
      ) {
        totalCount
        edges {
          node {
            ...diarioEconomicoPreview
          }
        }
      }

      diarioInternacional: allContentfulDiarioInternacional(
        sort: { fields: createdAt, order: DESC }
        limit: 1
      ) {
        totalCount
        edges {
          node {
            ...diarioInternacional
          }
        }
      }


      mercadosApertura: allContentfulMercadosALaApertura(
        sort: { fields: createdAt, order: DESC }
        limit: 5
      ) {
        totalCount
        edges {
          node {
            ...mercadosAperturaPreview
          }
        }
      }

      estrategiaDeInversion: allContentfulEstrategiaDeInversion(
        sort: { fields: createdAt, order: DESC }
        limit: 3
      ) {
        totalCount
        edges {
          node {
            ...estrategiaDeInversionPreview
          }
        }
      }

      flashEmisora: allContentfulFlashEmisora(
        sort: { fields: createdAt, order: DESC }
        limit: 1
      ) {
        totalCount
        edges {
          node {
            ...flashEmisoraPreview
          }
        }
      }

      flashTecnico: allContentfulFlashTecnico(
        sort: { fields: createdAt, order: DESC }
        limit: 1
      ) {
        totalCount
        edges {
          node {
            ...flashTecnicoPreview
          }
        }
      }

      flashEconomico: allContentfulFlashEconomico(
        sort: { fields: createdAt, order: DESC }
        limit: 1
      ) {
        totalCount
        edges {
          node {
            ...flashEconomicoPreview
          }
        }
      }

      flashInternacional: allContentfulFlashInternacional(
        sort: { fields: createdAt, order: DESC }
        limit: 1
      ) {
        totalCount
        edges {
          node {
            ...flashInternacionalPreview
          }
        }
      }

      flashRentaFija: allContentfulFlashRentaFija(
        sort: { fields: createdAt, order: DESC }
        limit: 1
      ) {
        totalCount
        edges {
          node {
            ...flashRentaFijaPreview
          }
        }
      }

      reporteTrimestral: allContentfulReporteTrimestral(
        sort: { fields: createdAt, order: DESC }
        limit: 1
      ) {
        totalCount
        edges {
          node {
            ...reporteTrimestralPreview
          }
        }
      }

      comentarioSemanal: allContentfulComentarioSemanal(
        sort: { fields: createdAt, order: DESC }
        limit: 1
      ) {
        totalCount
        edges {
          node {
            ...comentarioSemanalPreview
          }
        }
      }

      comentarioDiario: allContentfulComentarioDiario(
        sort: { fields: createdAt, order: DESC }
        limit: 1
      ) {
        totalCount
        edges {
          node {
            ...comentarioDiarioPreview
          }
        }
      }
    }
  `);
  const comentarioDiario = data.comentarioDiario.edges.map(
    (edge: { node: ContentfulComentarioDiario }) => {
      const {
        id,
        titulo,
        texto,
        createdAt,
        autor,
      } = edge.node;
      const categorySlug = 'comentario-diario';
      return {
        id,
        title: titulo,
        description: texto,
        category: 'Comentario Diario',
        slug: `/comentario-diario`,
        date: parseDate(createdAt),
        createdAt,
        categorySlug,
        author: autor?.nombre,
        avatar: autor?.foto,
        twitter: autor?.twitter
      };
    }
  );

  const comentarioSemanal = data.comentarioSemanal.edges.map(
    (edge: { node: ContentfulComentarioSemanal }) => {
      const {
        id,
        titulo,
        slug,
        texto,
        createdAt,
        autor,
      } = edge.node;
      const categorySlug = 'comentario-semanal';
      return {
        id,
        title: titulo,
        description: texto,
        category: 'Comentario Semanal',
        slug: `/comentario-semanal/${slug}`,
        date: parseDate(createdAt),
        createdAt,
        categorySlug,
        author: autor?.nombre,
        avatar: autor?.foto,
        twitter: autor?.twitter
      };
    }
  );

  const pulsoTecnicoPosts = data.pulsoTecnico.edges.map(
    (edge: { node: ContentfulPulsoTecnico }) => {
      const {
        id,
        titulo,
        resumen,
        slug,
        createdAt,
        autor,
        heroImage,
      } = edge.node;
      const categorySlug = 'diario-tecnico';
      return {
        id,
        title: titulo,
        description: resumen,
        category: 'Diario Técnico',
        //slug: `/tecnico/${categorySlug}/${slug}`,
        slug: `/tecnico/${slug}`,
        date: parseDate(createdAt),
        createdAt,
        categorySlug,
        author: autor?.nombre,
        fluid: heroImage,
      };
    }
  );

  const genericoPosts = data.generico.edges.map(
    (edge: { node: ContentfulGenerico }) => {
      const {
        id,
        titulo,
        resumen,
        slug,
        createdAt,
        autor,
        heroImage,
      } = edge.node;
      const categorySlug = 'temas-del-momento';
      return {
        id,
        title: titulo,
        description: resumen,
        category: 'Temas del Momento',
        //slug: `/tecnico/${categorySlug}/${slug}`,
        slug: `/temas-del-momento/${slug}`,
        date: parseDate(createdAt),
        createdAt,
        categorySlug,
        author: autor?.nombre,
        fluid: heroImage,
      };
    }
  );

  const carteraModeloPosts = data.carteraModelo.edges.map(
    (edge: { node: ContentfulCarteraModelo }) => {
      const { id, titulo, resumen, slug, createdAt, autor } = edge.node;
      const categorySlug = 'cartera';
      return {
        id,
        title: titulo,
        description: resumen,
        category: 'Cartera Modelo',
        slug: `/renta-variable/${slug}`,
        date: parseDate(createdAt),
        categorySlug,
        author: autor?.nombre,
      };
    }
  );

  const carteraTecnicoPosts = data.carteraTecnico.edges.map(
    (edge: { node: ContentfulCarteraTecnico }) => {
      const { id, titulo, resumen, slug, createdAt, autor } = edge.node;
      const categorySlug = 'cartera';
      return {
        id,
        title: titulo,
        description: resumen,
        category: 'Cartera Técnico',
        slug: `/tecnico/${slug}`,
        date: parseDate(createdAt),
        createdAt,
        categorySlug,
        author: autor?.nombre,
      };
    }
  );

  const diarioEconomicoPosts = data.diarioEconomico.edges.map(
    (edge: { node: ContentfulDiarioEconomico }) => {
      const {
        id,
        titulo,
        resumen,
        slug,
        createdAt,
        autor,
        fotoDePortada,
      } = edge.node;
      const categorySlug = 'diario-economico';
      return {
        id,
        title: titulo,
        description: resumen,
        category: 'Diario Económico',
        slug: `/economico/${slug}`,
        date: parseDate(createdAt),
        createdAt,
        categorySlug,
        author: autor?.nombre,
        fluid: fotoDePortada,
      };
    }
  );

  const diarioInternacionalPosts = data.diarioInternacional.edges.map(
    (edge: { node: ContentfulDiarioInternacional }) => {
      const {
        id,
        titulo,
        resumen,
        slug,
        createdAt,
        autor,
        heroImage,
      } = edge.node;
      const categorySlug = 'diario-internacional';
      return {
        id,
        title: titulo,
        description: resumen,
        category: 'Diario Internacional',
        slug: `/internacional/${slug}`,
        date: parseDate(createdAt),
        createdAt,
        categorySlug,
        author: autor?.nombre,
        fluid: heroImage,
      };
    }
  );

  const mercadoAperturaPosts = data.mercadosApertura.edges.map(
    (edge: { node: ContentfulMercadosALaApertura }) => {
      const {
        id,
        titulo,
        resumen,
        slug,
        createdAt,
        autor,
        comentarioDiario,
        autorComentarioDiario,
        fotoDePortada,
        mercadosEnElMundo,
        imagenApertura,
      } = edge.node;
      const categorySlug = 'mercados-a-la-apertura';
      return {
        id,
        title: titulo,
        description: resumen,
        category: 'Mercados a la Apertura',
        slug: `${categorySlug}/${slug}`,
        date: parseDate(createdAt),
        createdAt,
        categorySlug,
        author: autor?.nombre,
        authorObject: {
          name: autorComentarioDiario?.nombre,
          twitter: autorComentarioDiario?.twitter,
          avatar: autorComentarioDiario?.foto,
        },
        fluid: fotoDePortada,
        comentarioDiario: comentarioDiario?.texto,
        tituloComentarioDiario: comentarioDiario?.titulo,
        autorComentarioDiario: {
          id: comentarioDiario?.autor?.nombre,
          name: comentarioDiario?.autor?.nombre,
          twitter: comentarioDiario?.autor?.twitter,
          bio: comentarioDiario?.autor?.bio,
          avatar: comentarioDiario?.autor?.foto,
        },
        mercadosEnElMundo: mercadosEnElMundo?.json,
        imagenApertura: imagenApertura,
      };
    }
  );

  const estrategiaDeInversionPosts = data.estrategiaDeInversion.edges.map(
    (edge: { node: ContentfulEstrategiaDeInversion }) => {
      const {
        id,
        titulo,
        resumen,
        slug,
        createdAt,
        autor,
        heroImage,
      } = edge.node;
      const categorySlug = 'estrategia-de-inversion';
      return {
        id,
        title: titulo,
        description: resumen,
        category: 'Estrategia de Inversion',
        slug: `${categorySlug}/${slug}`,
        createdAt,
        date: parseDate(createdAt),
        categorySlug,
        author: autor?.nombre,
        fluid: heroImage,
      };
    }
  );

  const flashEmisoraPosts = data.flashEmisora.edges.map(
    (edge: { node: ContentfulFlashEmisora }) => {
      const { id, titulo, resumen, slug, createdAt, autor } = edge.node;
      const categorySlug = 'flash';
      return {
        id,
        title: titulo,
        description: resumen,
        category: 'Flash Emisora',
        slug: `/renta-variable/${slug}`,
        createdAt,
        date: parseDate(createdAt),
        categorySlug,
        author: autor?.nombre,
        avatar: autor?.foto,
      };
    }
  );

  const flashTecnicoPosts = data.flashTecnico.edges.map(
    (edge: { node: ContentfulFlashTecnico }) => {
      const { id, titulo, resumen, slug, createdAt, autor } = edge.node;
      const categorySlug = 'flash';
      return {
        id,
        title: titulo,
        description: resumen,
        category: 'Flash Técnico',
        //slug: `/tecnico/${categorySlug}/${slug}`,
        slug: `/tecnico/${slug}`,
        createdAt,
        date: parseDate(createdAt),
        categorySlug,
        author: autor?.nombre,
        avatar: autor?.foto,
      };
    }
  );

  const flashEconomicoPosts = data.flashEconomico.edges.map(
    (edge: { node: ContentfulFlashEconomico }) => {
      const { id, titulo, resumen, slug, createdAt, autor } = edge.node;
      const categorySlug = 'flash';
      return {
        id,
        title: titulo,
        description: resumen,
        category: 'Flash Económico',
        slug: `/economico/${slug}`,
        createdAt,
        date: parseDate(createdAt),
        categorySlug,
        author: autor?.nombre,
        avatar: autor?.foto,
      };
    }
  );

  const flashInternacionalPosts = data.flashInternacional.edges.map(
    (edge: { node: ContentfulFlashInternacional }) => {
      const { id, titulo, resumen, slug, createdAt, autor } = edge.node;
      const categorySlug = 'flash';
      return {
        id,
        title: titulo,
        description: resumen,
        category: 'Flash Internacional',
        slug: `/internacional/${slug}`,
        createdAt,
        date: parseDate(createdAt),
        categorySlug,
        author: autor?.nombre,
        avatar: autor?.foto,
      };
    }
  );

  const reporteTrimestralPosts = data.reporteTrimestral.edges.map(
    (edge: { node: ContentfulReporteTrimestral }) => {
      const { id, titulo, resumen, slug, createdAt, autor } = edge.node;
      const categorySlug = 'flash';
      return {
        id,
        title: titulo,
        description: resumen,
        category: 'Reporte Trimestral',
        slug: `/renta-variable/${slug}`,
        createdAt,
        date: parseDate(createdAt),
        categorySlug,
        author: autor?.nombre,
        avatar: autor?.foto,
      };
    }
  );

  const flashRentaFijaPosts = data.flashRentaFija.edges.map(
    (edge: { node: ContentfulFlashRentaFija }) => {
      const { id, titulo, resumen, slug, createdAt, autor } = edge.node;
      const categorySlug = 'flash';
      return {
        id,
        title: titulo,
        description: resumen,
        category: 'Flash Renta Fija',
        slug: `/renta-fija/${slug}`,
        date: parseDate(createdAt),
        categorySlug,
        author: autor?.nombre,
      };
    }
  );

  const flashesArray: unknown[] = [];
  flashesArray.push(...flashEmisoraPosts);
  flashesArray.push(...flashEconomicoPosts);
  flashesArray.push(...flashTecnicoPosts);
  flashesArray.push(...flashInternacionalPosts);
  flashesArray.push(...reporteTrimestralPosts);
  flashesArray.push(...flashRentaFijaPosts);

  // sacar el featured (el mas reciente de todos)
  var listaFlashesSort: any[] = flashesArray.sort(function (a: any, b: any) {
    return +new Date(b.createdAt).getTime() - +new Date(a.createdAt).getTime();
  });


  // console.log("flas")
  // console.log(flashesArray)


  const carterasArray: unknown[] = [];
  carterasArray.push(...carteraModeloPosts);
  carterasArray.push(...carteraTecnicoPosts);

  // lista de publicaciones
  const listaDePublicaciones: unknown[] = [];
  listaDePublicaciones.push(...estrategiaDeInversionPosts);
  listaDePublicaciones.push(...diarioEconomicoPosts);
  listaDePublicaciones.push(...mercadoAperturaPosts);
  listaDePublicaciones.push(...pulsoTecnicoPosts);
  listaDePublicaciones.push(...genericoPosts);

  // console.log('lista de publicaciones');
  // console.log(listaDePublicaciones);

  // sacar el featured (el mas reciente de todos)
  var listaSort: any[] = listaDePublicaciones.sort(function (a: any, b: any) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return +new Date(b.createdAt).getTime() - +new Date(a.createdAt).getTime();
  });


  //highlights 6 categorías
  const highlights: unknown[] = [];

  highlights.push(estrategiaDeInversionPosts[0]);
  highlights.push(diarioEconomicoPosts[0]);
  highlights.push(diarioInternacionalPosts[0]);
  highlights.push(mercadoAperturaPosts[0]);
  highlights.push(pulsoTecnicoPosts[0]);
  highlights.push(genericoPosts[0]);

  //ordenar por fecha de mas resciente

  var listaHigh: any[] = highlights.sort(function (a: any, b: any) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return +new Date(b.createdAt).getTime() - +new Date(a.createdAt).getTime();
  });

  // console.log("lista hig")
  // console.log(listaHigh)


  // .sort(function (
  //   a: unknown,
  //   b: unknown
  // ) {
  //   // eslint-disable-next-line no-param-reassign
  //   a = new Date(a.createdAt);
  //   // eslint-disable-next-line no-param-reassign
  //   b = new Date(b.createdAt);
  //   // eslint-disable-next-line no-nested-ternary
  //   return a < b ? -1 : a > b ? 1 : 0;
  // });

  // console.log('lista sort');
  // console.log(listaSort);
  // console.log('estr de inv');
  // console.log(estrategiaDeInversionPosts);
  const featuredArticle: any = listaSort[0]; // puse el 2 porque los primeros articulos del array son de estrategia y no tienen campo de createdAt, entonces se van al inicio de la listaSort

  listaSort = listaSort.filter(function (a: any) {
    return !(a.category == featuredArticle.category);
  });

  // sacar los recientes
  const listaRecientes: any[] = [];
  listaRecientes.push(listaSort[0]);
  listaSort = listaSort.filter(function (a: any) {
    return !(a.category == listaSort[0].category);
  });
  listaRecientes.push(listaSort[0]);
  listaSort = listaSort.filter(function (a: any) {
    return !(a.category == listaSort[0].category);
  });
  listaRecientes.push(listaSort[0]);
  listaSort = listaSort.filter(function (a: any) {
    return !(a.category == listaSort[0].category);
  });
  listaRecientes.push(listaSort[0]);


  // listaRecientes.push(estrategiaDeInversionPosts[0]);
  // listaRecientes.push(pulsoTecnicoPosts[0]);
  //listaRecientes.push(diarioEconomicoPosts[0]);
  // listaRecientes.push(mercadoAperturaPosts[0]);

  // sacar los destacados
  const listaDestacados: unknown[] = [];
  listaDestacados.push(pulsoTecnicoPosts[0]);
  listaDestacados.push(diarioEconomicoPosts[0]);
  listaDestacados.push(mercadoAperturaPosts[0]);

  // sacar comentario diario y mercados en el mundo
  // const comentarioDiario: any = {
  //   title: mercadoAperturaPosts[0].tituloComentarioDiario,
  //   text: mercadoAperturaPosts[0].comentarioDiario,
  //   slug: mercadoAperturaPosts[0].slug,
  //   author: mercadoAperturaPosts[0].autorComentarioDiario,
  //   date: parseDate(mercadoAperturaPosts[0].createdAt),
  // };

  const mercadosEnElMundo: any = {
    text: mercadoAperturaPosts[0].mercadosEnElMundo,
    fluid: mercadoAperturaPosts[0].imagenApertura,
    slug: mercadoAperturaPosts[0].slug,
  };

  const home: HomePostsProps = {
    listaHigh,
    pulsoTecnico: pulsoTecnicoPosts,
    carteraModelo: carteraModeloPosts,
    diarioEconomico: diarioEconomicoPosts,
    mercadoApertura: mercadoAperturaPosts,
    generico: genericoPosts,
    estrategiaDeInversion: estrategiaDeInversionPosts,
    flashes: listaFlashesSort,
    carteras: carterasArray,
    featured: featuredArticle,
    recentArticles: listaRecientes,
    featuredArticles: listaDestacados,
    comentarioDiario: comentarioDiario[0],
    mercadosEnElMundo,
    flashInternacional: flashEconomicoPosts[0],
    diarioInternacional: diarioInternacionalPosts[0],
    comentarioSemanal
  };

  return home;
};
