import { graphql, useStaticQuery } from 'gatsby';
import { ContentfulGenerico } from '../../../types/graphql-types';
import parseDate from '../../utils/parseDate';
import { PostPreview as PostsPreviewProps } from '../../models/PostPreview';

export const useGenericoPosts = (): PostsPreviewProps[] => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulGenerico(
        sort: { fields: createdAt, order: DESC }
        limit: 4
      ) {
        totalCount
        edges {
          node {
            ...genericoPreview
          }
        }
      }
    }
  `);

  const genericoPosts = data.allContentfulGenerico.edges.map(
    (edge: { node: ContentfulGenerico }) => {
      const { id, titulo, resumen, slug, createdAt, autor } = edge.node;
      const categorySlug = 'temas-del-momento';
      return {
        id,
        title: titulo,
        description: resumen,
        category: 'Temas del Momento',
        slug: `/${categorySlug}/${slug}`,
        date: parseDate(createdAt),
        categorySlug,
        author: autor?.nombre,
      };
    }
  );

  return genericoPosts;
};
