import { graphql, useStaticQuery } from 'gatsby';
import { ContentfulSemanarioFinanciero } from '../../../types/graphql-types';
import parseDate from '../../utils/parseDate';
import { PostPreview as PostsPreviewProps } from '../../models/PostPreview';

export const useSemanarioFinancieroPosts = (): PostsPreviewProps[] => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulSemanarioFinanciero(
        sort: { fields: createdAt, order: DESC }
        limit: 4
      ) {
        totalCount
        edges {
          node {
            ...semanarioFinancieroPreview
          }
        }
      }
    }
  `);

  const semanarioFinancieroPosts = data.allContentfulSemanarioFinanciero.edges.map(
    (edge: { node: ContentfulSemanarioFinanciero }) => {
      const { id, titulo, resumen, slug, createdAt, autor, pdf } = edge.node;
      const categorySlug = 'semanario-financiero';
      return {
        id,
        title: titulo,
        description: resumen,
        category: 'Semanario Financiero',
        slug: `/${categorySlug}/${slug}`,
        date: parseDate(createdAt),
        categorySlug,
        author: autor?.nombre,
        pdf: pdf?.file?.url
      };
    }
  );

  return semanarioFinancieroPosts;
};
